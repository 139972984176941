import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  _getHeaders() {
    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetMenu() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Menu/GetMenu",headers);
  }

  GetMenulist(_site:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Menu/GetMenu?site="+_site,headers);
  }

  UpdateMenu(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Menu/UpdateMenu", obj,headers);
  }

}
