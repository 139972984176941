import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  
  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAll() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Sponsor/GetAll",headers);
  }

  GetSponsorDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Sponsor/GetSponsorDetail/" + obj,headers);
  }

  PostSponsor(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Sponsor/PostSponsor", obj,headers);
  }

  PostSponsorUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Sponsor/PostSponsorUpdate", obj,headers);
  }

  DeleteSponsor(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };

    return this.http.delete(this.baseUrl+"api/Sponsor/DeleteSponsor",options);
  }

  
}
