import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded'
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllLectureByMe(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/YoutubeWeb/GetAllYoutubeByMe?id=" + id,{responseType: 'json'});
  }

  GetAllYoutube() : Observable<any> {
    return this.http.get(this.baseUrl+"api/YoutubeWeb/GetAllYoutube",{responseType: 'json'});
  }

  PostAddYoutubeLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/YoutubeWeb/PostAddYoutubeLink", obj,headers);
  }

  DeleteYoutubeLink(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/YoutubeWeb/DeleteYoutubeLink", obj);
  }
}
