import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'removelastword'
})

export class removelastwordPipe implements PipeTransform {

transform(value: string): string {
   let result = value || '';

   if (value != null) {
    const last = value.charAt(value.length - 1);
    if(last == "s"){
      result = value.slice(0, -1);
    }
   }

   return result;
 }
}