import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
import {ApiResponse} from "../model/api-response";
import {PostPaymentlist} from "../model/api-post";

@Injectable({
  providedIn: 'root'
})
export class ApproverService {

  _getHeaders() {
    // let headers = new Headers();    
    // headers.append('Content-Type', 'x-www-form-urlencoded');
    // return headers;

    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllApprover() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/ApproverItem/GetAllApprover",headers);
  }

  GetAllApproverLecture() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/ApproverItem/GetAllApproverLecture",headers);
  }


  PostAddDesignation(obj: any): Observable<any> {
    let headers = this._getHeaders();
     return this.http.post(this.baseUrl+"api/ApproverItem/PostApprove", obj,headers);
  }

  PostApproveLecture(obj: any): Observable<any> {
    let headers = this._getHeaders();
     return this.http.post(this.baseUrl+"api/ApproverItem/PostApproveLecture", obj,headers);
  }
}
