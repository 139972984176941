
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminPaymentService} from './admin-payment.service';
import {ApplicationService} from './application.service';
import {ApproverService} from './approver.service';
import {DesignationService} from './designation.service';
import {DonationOptionService} from './donation-option.service';
import {EditProfileService} from './edit-profile.service';
import {EventService} from './event.service';
import {GrandLodgeService} from './grand-lodge.service';
import {JournalService} from './journal.service';
import {LectureService} from './lecture.service';
import {LodgeService} from './lodge.service';
import {OfficersService} from './officers.service';
import {ProfileService} from './profile.service';
import {SettingService} from './setting.service';
import {UserSettingService} from './user-setting.service';
import {WhatsnewService} from './whatsnew.service';
import {YoutubeService} from './youtube.service';
import {FileuploadService} from './fileupload.service';
import {PaynowService} from './paynow.service';
import {MemberService} from './member.service';
import {ExcelServicesService} from './excel-services.service';
import { AuthGuard } from '../guards/auth-guard.service';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { DashboardService } from './dashboard.service';
import { GroupService } from './group.service';
import { DegreeService } from './degree.service';
import { NotificationService } from './notification.service';
import { PushnotificationService } from './pushnotification.service';
import { LecturecategoryService } from './lecturecategory.service';
import { DesignationGroupService } from './designation-group.service';
import { BusinesscategoryService } from './businesscategory.service';
import { AppconfigService } from './appconfig.service';
import { SponsorService } from './sponsor.service';
import { DresscodeService } from './dresscode.service';
import { RsvpService } from './rsvp.service';
import { DatabaseService } from './database.service';
import { SiteinfoService } from './siteinfo.service';
import { EmagazineService } from './emagazine.service';
import { ExternallinkService } from './externallink.service';
import { PointmemberService } from './pointmember.service';
import { PointcategoryService } from './pointcategory.service';
import { PointawardService } from './pointaward.service';
import { MenuService } from './menu.service';
import { SearchendpointService } from './searchendpoint.service';
import { BusinessService } from './business.service';
import { MailtemplateService } from './mailtemplate.service';
import { PaymentlinkService } from './paymentlink.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    AuthGuard,
    AdminPaymentService,
    ApplicationService,
    ApproverService,
    DesignationService,
    DonationOptionService,
    EditProfileService,
    EventService,
    GrandLodgeService,
    JournalService,
    LectureService,
    LodgeService,
    OfficersService,
    ProfileService,
    SettingService,
    UserSettingService,
    WhatsnewService,
    YoutubeService,
    MemberService,
    ConfirmationDialogService,
    ExcelServicesService,
    FileuploadService,
    PaynowService,
    DashboardService,
    GroupService,
    DegreeService,
    NotificationService,
    PushnotificationService,
    LecturecategoryService,
    DesignationGroupService,
    BusinesscategoryService,
    AppconfigService,
    SponsorService,
    DresscodeService,
    RsvpService,
    DatabaseService,
    SiteinfoService,
    EmagazineService,
    ExternallinkService,
    PointmemberService,
    PointcategoryService,
    PointawardService,
    MenuService,
    SearchendpointService,
    BusinessService,
    MailtemplateService,
    PaymentlinkService
  ]
})

export class ServiceModule {
}
