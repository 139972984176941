import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent } from '@nebular/auth';
import {ProfileService} from '../service/profile.service';
import {MenuService} from '../service/menu.service';
import {ToasterService} from '../service/toaster.service';
import {NgbModal, ModalDismissReasons,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  email = '';
  password = '';
  remember = false;
  site_arr = [];

  _data = null;
  _data_main = [];
  _data_sub = [];

  btnname= "Cancel";

  _firebase_id = "";

  constructor(private dialogService: NbDialogService,private ToasterService: ToasterService,private router: Router,private ProfileService:ProfileService,private MenuService: MenuService) {
    if(window.localStorage.getItem('AdminOlympusToken')) {
      var _url = window.localStorage.getItem("AdminOlympus_defaulturl");
      if(_url != null){
        window.location.href = _url;
      }
      
      //this.router.navigate(['/admin/graphs']);
      
    }
  }

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog,
      { context: 'this is some additional data passed to dialog'});
  }

  click_site(n,ref){
    this.btnname = "Processing...";
    localStorage.setItem("AdminOlympus_Site", n.id);
    let data_obj = {
      Email: this.email,
      Password: this.password,
      FirebaseUserId: this._firebase_id
    }

  this.loadmenu(n.id);  
  this.ProfileService.tokenfull(data_obj,n.id).subscribe(
    datalogin => {
      if(datalogin == null){
        this.ToasterService.showToast("warning", "Login", "Something is wrong.");
      }
      else{
        let key = 'AdminOlympusToken';
        localStorage.setItem(key, datalogin.token);
        localStorage.setItem("AdminOlympus_FirstName", datalogin.firstName);
        localStorage.setItem("AdminOlympus_LastName", datalogin.lastName);
        localStorage.setItem("AdminOlympus_LodgeId", datalogin.lodgeId);
        localStorage.setItem("AdminOlympus_SubLodgeId", datalogin.subLodgeId);
        localStorage.setItem("AdminOlympus_UserId", datalogin.id);
        localStorage.setItem("AdminOlympus_Email", datalogin.email);
        localStorage.setItem("AdminOlympus_UserImage", datalogin.userImage);
        localStorage.setItem("AdminOlympus_Role_id", datalogin.role_id);
        localStorage.setItem("AdminOlympus_GoogleStoragePath", datalogin.google_storage_path);
        localStorage.setItem("AdminOlympus_ConfigValid", datalogin.config_valid);
        localStorage.setItem("AdminOlympus_Search_key", datalogin.search_key);
        localStorage.setItem("AdminOlympus_Roles", datalogin.roles);

        if(this.remember == true){

        }
        
        if(datalogin.config_valid == "Y"){
          this.role_link(datalogin.roles);
        }
        else{
          localStorage.setItem("AdminOlympus_defaulturl", "/admin/graphs");
          window.location.href = "/admin/configuration/app-settings";
        }
        
      }
   },
   error => {
    this.ToasterService.showToast("warning", "Login", "please check your username & password");
    this.btnname = "Cancel";
  });
  }

  loginSubmit(){
    this.site_arr = [];
    var that = this;
    localStorage.setItem("AdminOlympus_Site", "");
    var offset = (new Date).toLocaleString('en-US', {
      hour12: false,
      hour: '2-digit',
      timeZoneName: 'long'
  }).replace(/^\d\d /, '');

  this.ProfileService.FireaseBaseAuthSignIn(this.email,this.password).then(
    (data) => {
      
        var emailVerified = data.user.emailVerified;
        if(emailVerified == true){

          this.ProfileService.GetUserEmail(this.email).subscribe(
            (dataemailsite) => {
              if(dataemailsite == null){
                this.ToasterService.showToast("warning", "Login", "Something is wrong.");
              }
              else{
                this._firebase_id = data.user.uid;
                var site = dataemailsite.sites[0];
                var _sites = dataemailsite.sites;
                _sites.forEach(function (value) {
                  var _site = value;
                  _site = _site.charAt(0).toUpperCase() + _site.slice(1);;
                  //_site = _site.replace(" ", "-");

                  var display_text = "";
                  var arrSpilt = _site.split('-');
                  arrSpilt.forEach(function (item) {
                    display_text = display_text + item + " ";
                  });

                  var _siteobj = {
                      id : value,
                      name : display_text.trim()
                  };
                  that.site_arr.push(_siteobj);
                });
                if(that.site_arr.length > 1){
                  $("#btnpopup").click();
                } 
                else{
                  localStorage.setItem("AdminOlympus_Site", site);
                  let data_obj = {
                    Email: this.email,
                    Password: this.password,
                    FirebaseUserId: data.user.uid
                  }
                
                this.loadmenu(site);
                this.ProfileService.tokenfull(data_obj,site).subscribe(
                  datalogin => {
                    if(datalogin == null){
                      this.ToasterService.showToast("warning", "Login", "Something is wrong.");
                    }
                    else{
                      let key = 'AdminOlympusToken';
                      localStorage.setItem(key, datalogin.token);
                      localStorage.setItem("AdminOlympus_FirstName", datalogin.firstName);
                      localStorage.setItem("AdminOlympus_LastName", datalogin.lastName);
                      localStorage.setItem("AdminOlympus_LodgeId", datalogin.lodgeId);
                      localStorage.setItem("AdminOlympus_SubLodgeId", datalogin.subLodgeId);
                      localStorage.setItem("AdminOlympus_UserId", datalogin.id);
                      localStorage.setItem("AdminOlympus_Email", datalogin.email);
                      localStorage.setItem("AdminOlympus_UserImage", datalogin.userImage);
                      localStorage.setItem("AdminOlympus_Role_id", datalogin.role_id);
                      localStorage.setItem("AdminOlympus_GoogleStoragePath", datalogin.google_storage_path);
                      localStorage.setItem("AdminOlympus_ConfigValid", datalogin.config_valid);
                      localStorage.setItem("AdminOlympus_Search_key", datalogin.search_key);
                      localStorage.setItem("AdminOlympus_Roles", datalogin.roles);
                      
                      if(this.remember == true){
  
                      }
                      
                      if(datalogin.config_valid == "Y"){
                        //window.location.href = "/admin/graphs";
                          this.role_link(datalogin.roles);
                      }
                      else{
                        localStorage.setItem("AdminOlympus_defaulturl", "/admin/graphs");
                        window.location.href = "/admin/configuration/app-settings";
                      }
                      
                      //this.router.navigate(['/admin/graphs']);
                    
                    }
                 },
                 error => {
                  this.ToasterService.showToast("warning", "Login", "please check your username & password");
                });
                }
                //site_arr
               
              }
          });
        }
        else{
          this.ToasterService.showToast("warning", "Login", "Please validate your email address. Kindly check your inbox.");
        }
        
      // success, show some message
      
  
    },
    err => {
      this.ToasterService.showToast('warning', 'Login', err.message);
      // handle errors
    });
  }

  loadmenu(site){
    this.MenuService.GetMenulist(site).subscribe(
      (data) => {
        if(data!= null){
          this._data = data;
          this._data_main = data.main_names;
          this._data_sub = data.sub_names;
          this.load_main();
          this.load_sub();
          localStorage.setItem("AdminOlympus_menu", JSON.stringify(data));
          this._data_main = data.main_names;
        }
      },
      err => {
        // handle errors
      });
  }

  check_link(name){
    var _status = false;
    var _response = this._data_main.find(x => x.default_name === name);
    if(_response != null){
      _status = _response.enablelink;
    }
    return _status;
  }

  role_link(_roles){
     var that = this;
    if(_roles == null || _roles == ""){
      // all display
      localStorage.setItem("AdminOlympus_defaulturl", "/admin/graphs");
      window.location.href = "/admin/graphs";
    }
    else{
        var rolelist = _roles;
        //var rolelist = _roles.split(',');
        var enableMenus = [];
        if(rolelist.indexOf("owner") > -1 || rolelist.indexOf("all") > -1 || rolelist.indexOf("dashboard") > -1){
            // all display
            localStorage.setItem("AdminOlympus_defaulturl", "/admin/graphs");
            window.location.href = "/admin/graphs";
        }
        else{
          
          rolelist.forEach(function (role) {
            if(role == "analytics"){
              if(that.check_link("Analytics") == true){
                enableMenus.push({"name" : "Analytics", "order" : 1, "link": "/admin/graphs"});
              }
            }
            else if(role == "announcements"){
              if(that.check_link("Announcements") == true){
                enableMenus.push({"name" : "Announcements", "order" : 3, "link": "/admin/settings/notifications"});
              }
            }
            else if(role == "blog"){
              if(that.check_link("Blog") == true){
                enableMenus.push({"name" : "Blog", "order" : 9, "link": "/admin/resources/lectures"});
              }
            }
            else if(role == "business"){
              if(that.check_link("Business") == true){
                enableMenus.push({"name" : "Business", "order" : 8, "link": "/admin/business/businesscategorys"});
              }
            }
            else if(role == "degrees"){
              if(that.check_link("Degrees") == true){
                enableMenus.push({"name" : "Degrees", "order" : 14, "link": "/admin/resources/content"});
              }
            }
            else if(role == "donations"){
              if(that.check_link("Donations") == true){
                enableMenus.push({"name" : "Donations", "order" : 11, "link": "/admin/settings/charitiesoption"});
              }
            }
            else if(role == "dues"){
              if(that.check_link("Dues") == true){
                enableMenus.push({"name" : "Dues", "order" : 10, "link": "/admin/settings/planprice"});
              }
            }
            else if(role == "payments"){
              if(that.check_link("Payments") == true){
                enableMenus.push({"name" : "Payments", "order" : 10, "link": "/admin/payments/list"});
              }
            }
            else if(role == "emails"){
              if(that.check_link("Emails") == true){
                enableMenus.push({"name" : "Emails", "order" : 7, "link": "/admin/emails/campaignlist"});
              }
            }
            else if(role == "events"){
              if(that.check_link("Events") == true){
                enableMenus.push({"name" : "Events", "order" : 6, "link": "/admin/events/eventlist"});
              }
            }
            else if(role == "magazines"){
              if(that.check_link("Magazine") == true){
                enableMenus.push({"name" : "Magazine", "order" : 15, "link": "/admin/emagazine/list"});
              }
            }
            else if(role == "media"){
              if(that.check_link("Media") == true){
                enableMenus.push({"name" : "Media", "order" : 12, "link": "/admin/gallerys/list"});
              }
            }
            else if(role == "officers"){
              if(that.check_link("Officers") == true){
                enableMenus.push({"name" : "Officers", "order" : 5, "link": "/admin/valleyofficer/list"});
              }
            }
            else if(role == "notifications"){
              if(that.check_link("Notifications") == true){
                enableMenus.push({"name" : "Notifications", "order" : 2, "link": "/admin/settings/pushnotifications"});
              }
            }
            else if(role == "settings"){
              if(that.check_link("Settings") == true){
                enableMenus.push({"name" : "Settings", "order" : 17, "link": "/admin/configuration/app-settings"});
              }
            }
            else if(role == "store"){
              if(that.check_link("Store") == true){
                enableMenus.push({"name" : "Store", "order" : 4, "link": "/admin/settings/cashregisteroptions"});
              }
            }
            else if(role == "users"){
              if(that.check_link("Users") == true){
                enableMenus.push({"name" : "Users", "order" : 13, "link": "/admin/settings/users"});
              }
            }
            else if(role == "points"){
              if(that.check_link("Points") == true){
                enableMenus.push({"name" : "Points", "order" : 16, "link": "/admin/points/members"});
              }
            }
            else if(role == "reports"){
              if(that.check_link("Reports") == true){
                enableMenus.push({"name" : "Reports", "order" : 18, "link": "/admin/settings/paymenthistory"});
              }
            }
          });
         var list = enableMenus.sort((a, b) => (a.order > b.order) ? 1 : -1);
         if(list.length > 0){
           var _link = list[0].link;
           localStorage.setItem("AdminOlympus_defaulturl", _link);
           window.location.href = _link;
         }
         

        }
    }
  }

  load_main(){
    
    var _response = null;
    _response = this._data_main.find(x => x.default_name === "Analytics");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_analytics",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_analytics","Analytics");
    }
    _response = this._data_main.find(x => x.default_name === "Notifications");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_notifications",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_notifications","Notifications");
    }
    _response = this._data_main.find(x => x.default_name === "Announcements");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_announcements",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_announcements","Announcements");
    }
    _response = this._data_main.find(x => x.default_name === "Settings");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_settings",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_settings","Settings");
    }
    _response = this._data_main.find(x => x.default_name === "Reports");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_reports",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_reports","Reports");
    }
  
  }

  load_sub(){
    var _response = null;
    _response = this._data_sub.find(x => x.default_name === "Products" && x.group_name === "Store");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_store_product",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_store_product","Products");
    }
    _response = this._data_sub.find(x => x.default_name === "Categories" && x.group_name === "Store");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_store_category",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_store_category","Categories");
    }
    _response = this._data_sub.find(x => x.default_name === "Officers" && x.group_name === "Officers");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_officers_officers",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_officers_officers","Officers");
    }
    _response = this._data_sub.find(x => x.default_name === "Titles" && x.group_name === "Officers");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_officers_titles",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_officers_titles","Titles");
    }
    _response = this._data_sub.find(x => x.default_name === "Rites" && x.group_name === "Officers");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_officers_rites",_name);
     }
     else{
      localStorage.setItem("AdminOlympus_nm_officers_rites","Rites");
    }
    _response = this._data_sub.find(x => x.default_name === "Jurisdictions" && x.group_name === "Officers");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
     localStorage.setItem("AdminOlympus_nm_officers_jurisdictions",_name);
     }
     else{
      localStorage.setItem("AdminOlympus_nm_officers_jurisdictions","Jurisdictions");
    }
    _response = this._data_sub.find(x => x.default_name === "Events" && x.group_name === "Events");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_events_events",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_events_events","Events");
    }
    _response = this._data_sub.find(x => x.default_name === "Dress Code" && x.group_name === "Events");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_events_dresscode",_name);
   }
   else{
    localStorage.setItem("AdminOlympus_nm_events_dresscode","Dress Code");
  }
    _response = this._data_sub.find(x => x.default_name === "RSVP Option" && x.group_name === "Events");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_events_rsvp",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_events_rsvp","RSVP Option");
    }
    _response = this._data_sub.find(x => x.default_name === "Virtual Meeting" && x.group_name === "Events");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_events_virtual",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_events_virtual","Virtual Meeting");
    }
    _response = this._data_sub.find(x => x.default_name === "Campaigns" && x.group_name === "Emails");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_emails_campaigns",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_emails_campaigns","Campaigns");
    }
    _response = this._data_sub.find(x => x.default_name === "Archives" && x.group_name === "Emails");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_emails_archives",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_emails_archives","Archives");
    }
    _response = this._data_sub.find(x => x.default_name === "Lists" && x.group_name === "Emails");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_emails_lists",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_emails_lists","Lists");
    }
    _response = this._data_sub.find(x => x.default_name === "Contacts" && x.group_name === "Emails");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_emails_contact",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_emails_contact","Contacts");
    }
    _response = this._data_sub.find(x => x.default_name === "Categories" && x.group_name === "Business");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_business_categories",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_business_categories","Categories");
    }
    _response = this._data_sub.find(x => x.default_name === "Business" && x.group_name === "Business");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_business_business",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_business_business","Business");
    }
    _response = this._data_sub.find(x => x.default_name === "Blog" && x.group_name === "Blog");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_blog_blog",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_blog_blog","Blog");
    }
    _response = this._data_sub.find(x => x.default_name === "Categories" && x.group_name === "Blog");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_blog_categories",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_blog_categories","Categories");
    }
    _response = this._data_sub.find(x => x.default_name === "Dues Plan" && x.group_name === "Dues");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_dues_dueplan",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_dues_dueplan","Dues Plan");
    }
    _response = this._data_sub.find(x => x.default_name === "Processing Date" && x.group_name === "Dues");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_dues_processing",_name);
     }
     else{
      localStorage.setItem("AdminOlympus_nm_dues_processing","Processing Date");
    }
    _response = this._data_sub.find(x => x.default_name === "Payments" && x.group_name === "Payments");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_payments_payments",_name);
     }
     else{
      localStorage.setItem("AdminOlympus_nm_payments_payments","Payments");
    }
    _response = this._data_sub.find(x => x.default_name === "Charities" && x.group_name === "Donations");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_donations_charities",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_donations_charities","Charities");
    }
    _response = this._data_sub.find(x => x.default_name === "Charities Amount" && x.group_name === "Donations");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_donations_charities_amount",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_donations_charities_amount","Charities Amount");
    }
    _response = this._data_sub.find(x => x.default_name === "Gallery" && x.group_name === "Media");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_media_gallery",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_media_gallery","Gallery");
    }
    _response = this._data_sub.find(x => x.default_name === "Users" && x.group_name === "Users");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_users_users",_name);
     }
     else{
      localStorage.setItem("AdminOlympus_nm_users_users","Users");
    }
    _response = this._data_sub.find(x => x.default_name === "Groups" && x.group_name === "Users");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_users_groups",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_users_groups","Groups");
    }
    _response = this._data_sub.find(x => x.default_name === "Database" && x.group_name === "Users");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_users_database",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_users_database","Database");
    }
    _response = this._data_sub.find(x => x.default_name === "Approval" && x.group_name === "Users");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_users_approvel",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_users_approvel","Approval");
    }
    _response = this._data_sub.find(x => x.default_name === "Content" && x.group_name === "Degrees");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
     localStorage.setItem("AdminOlympus_nm_degrees_content",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_degrees_content","Content");
    }
    _response = this._data_sub.find(x => x.default_name === "Audience" && x.group_name === "Degrees");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_degrees_Audience",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_degrees_Audience","Audience");
    }
    _response = this._data_sub.find(x => x.default_name === "Magazine" && x.group_name === "Magazine");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_magazine_magazine",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_magazine_magazine","Magazine");
    }
    _response = this._data_sub.find(x => x.default_name === "Labels" && x.group_name === "Magazine");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_magazine_label",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_magazine_label","Labels");
    }
    _response = this._data_sub.find(x => x.default_name === "Members" && x.group_name === "Points");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_points_members",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_points_members","Members");
    }
    _response = this._data_sub.find(x => x.default_name === "Activities" && x.group_name === "Points");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_points_activities",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_points_activities","Activities");
    }
    _response = this._data_sub.find(x => x.default_name === "Awards" && x.group_name === "Points");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_points_awards",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_points_awards","Awards");
    }
    _response = this._data_sub.find(x => x.default_name === "Approval" && x.group_name === "Points");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_points_approvel",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_points_approvel","Approval");
    }
    _response = this._data_sub.find(x => x.default_name === "Reports" && x.group_name === "Reports");
    if(_response != null){
      var _name = _response.default_status == true ? _response.default_name : _response.alt_name;
      localStorage.setItem("AdminOlympus_nm_reports_reports",_name);
    }
    else{
      localStorage.setItem("AdminOlympus_nm_reports_reports","Reports");
    }
   
  }

  ngOnInit(): void{
   
  }
}
