import { Component, OnInit } from '@angular/core';
import { NbAuthComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-auth',
  template: `
    <nb-layout>
      <nb-layout-column>
        <nb-card>
          <nb-card-body>
            <nb-auth-block>
              <router-outlet></router-outlet>
            </nb-auth-block>
          </nb-card-body>
        </nb-card>
      </nb-layout-column>
    </nb-layout>
  `,
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent extends NbAuthComponent {
}

// export class AuthComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
