import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams
} from '@angular/common/http';
import {environment} from "../../environments/environment";
import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  _site = window.localStorage.getItem('AdminOlympus_Site');
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      var offset = new Date().getTimezoneOffset();
      var params = new HttpParams();
      params = params.append('site', this._site); 
      params = params.append('offset', offset.toString()); 
    
       let key = 'AdminOlympusToken';
       let myItem = localStorage.getItem(key);

       req= req.clone({
        params:params
      });
      
       if (!req.headers.has('Authorization')) {
        if (req.url.indexOf('search.olympusweb.io') === -1) {
          req= req.clone({
            headers: req.headers.append('Authorization', 'Bearer ' + myItem),
            params:params
          });
        }
       }
       else{
        
       }
       //req= req.clone({headers: req.headers.append('Accept', 'application/json')});
      
      return next.handle(req);

        // if (myItem) {
        //   req = req.clone({
        //         setHeaders: { 
        //             Authorization: `Bearer ${myItem}`
        //         }
        //     });
        // }

        // return next.handle(req);
  }
}