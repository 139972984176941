import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PushnotificationService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  _getHeadersNotification() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      "Accept": "application/json",
      "Cache-Control": "no-cache",
      'Authorization': 'key=AAAAhAm9_QM:APA91bFc9qfLXX0VYIL5fkZf-HjoqkJrH1Z6nFxLOcjx3n0A8JoIUUaQTLDxewN-GQRerWhvzHU_0SfwrE8WCM87h7P2pz3-KdUkXX1fUjBiLAXXp4a-9bkt0vPxdh5ylgFcyEJU174_',
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostNotificationPagination(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetSingal(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PushNotification/GetSingal/"+obj,headers);
  }

  GetNotificationReceipts(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PushNotification/GetNotificationReceipts/"+obj,headers);
  }

  PostNotificationSendm(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/PushNotification/PostNotificationSendm?offset=" + offset, obj,headers);
  }

  PostAddNotification(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/PushNotification/PostAddNotification?offset=" + offset, obj,headers);
  }

  PutUpdateNotification(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.put(this.baseUrl+"api/PushNotification/PutUpdateNotification?offset=" + offset, obj,headers);
  }

  DeleteNotification(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/PushNotification/DeleteNotification", options);
  }

  SendAll(title: any,desc: any): Observable<any> {
    let headers = this._getHeadersNotification();
    var obj = {
      "notification": {
        "title": title, 
        "body": desc
      },
      "to" : "/topics/all"
       
     }
    return this.http.post("https://fcm.googleapis.com/fcm/send", obj,headers);
  }
  SendTokens(tokens:any,title: any,desc: any): Observable<any> {
    let headers = this._getHeadersNotification();
    var obj = {
      "notification": {
        "title": title, 
        "body": desc
      },
      "registration_ids" : tokens
       
     }
    return this.http.post("https://fcm.googleapis.com/fcm/send", obj,headers);
  }
}
