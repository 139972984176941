export const environment = {
  production: false,
  ServerUrl: "https://api-x7bsi3dmrq-uk.a.run.app/",
  MicroserviceURL: "https://reports-x7bsi3dmrq-uk.a.run.app",
  ReportsApiKey: "dGI70H1nsZKJ4hiW7hgEoJDg",
  Auth_Key:"2xFxSrnchz73KurdDaRe5oT03yTFGF33dciZPdGI8M9b8JqE7NQWIyZze8UKOAfK",
  Auth_Secret:"MvdscmVByu",
  GoogleStoragePath: "",
  firebase: {
    apiKey: "AIzaSyDtUFk2CLJ4fG_6IBsQIfgch2fOatQVK7o",
    authDomain: "olympus-317810.firebaseapp.com",
    projectId: "olympus-317810",
    storageBucket: "olympus-317810.appspot.com",
    messagingSenderId: "361871000284",
    appId: "1:361871000284:web:a9b0b01eb07cb17a82ea19",
    measurementId: "G-SFNP1Y1FXX"
  }
};