import { Injectable } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import 'style-loader!angular2-toaster/toaster.css';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 4000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  constructor(private toastrService: NbToastrService,private _notifications: NotificationsService) { }

  // public showToast(type: NbComponentStatus, title: string, body: string) {
  //   const config = {
  //     status: type,
  //     destroyByClick: this.destroyByClick,
  //     duration: this.duration,
  //     hasIcon: this.hasIcon,
  //     position: this.position,
  //     preventDuplicates: this.preventDuplicates,
  //   };
    
  //   this.index += 1;
  //   this.toastrService.show(
  //     body,
  //     title,
  //     config);
  // }

  public showToast(type: string, title: string, body: string) {
      const config = {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        animate: 'fromRight'
    };
    

    if(type == "warning"){
      this._notifications.create(title, body, NotificationType.Warn,config)
    }
    if(type == "error"){
      this._notifications.create(title, body, NotificationType.Error,config)
    }
    if(type == "success"){
      this._notifications.create(title, body, NotificationType.Success,config)
     }
  }
}
