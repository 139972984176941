import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostPaginationUrl(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetDetail() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetDetail",headers);
  }

  GetAllSlider() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetAllSlider",headers);
  }

  GetLink() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetLink",headers);
  }

  GetSliderDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetSlider/" + obj,headers);
  }

  GetPolicy() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PolicyTerms/GetPolicy",headers);
  }

  GetTermsOfUse() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PolicyTerms/GetTermsOfUse",headers);
  }

  GetSliderLink(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetSliderLink/" + obj,headers);
  }

  PostDetailSlider(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/UserSet/PUTSlider", obj,headers);
  }

  PostDetailVideoSlider(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/UserSet/PUTSliderWithVideo", obj,headers);
  }

  PostSlider(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/UserSet/PostSlider", obj,headers);
  }

  DeleteSlider(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteSlider/"+obj,headers);
  }

  
  GetSingalLink(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/UserSet/GetSingalLink/" + obj,headers);
  }

  PostLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/UserSet/PostLink", obj,headers);
  }

  PutLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/UserSet/PutLink", obj,headers);
  }

  DeleteLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteLink/"+ obj,headers);
  }

  PostSliderLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/UserSet/PostSliderLink", obj,headers);
  }

  DeleteSliderLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteSliderLink/"+ obj,headers);
  }

  PostPolicy(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PolicyTerms/PostPolicy", obj,headers);
  }

  PostTerms(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PolicyTerms/PostTerms", obj,headers);
  }
  DeleteLogo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteLogo/"+obj,headers);
  }
  DeleteFaveico(): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteFaveico",headers);
  }
}
