import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class SiteinfoService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded'
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  

  PostSite(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Site/PostSite", obj,headers);
  }

  DeleteSite(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Site/DeleteSite", options);
  }

  listDomain() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Site/listDomain",headers);
  }

  listSearchDomain(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Site/listSearchDomain?search="+obj,headers);
  }

  getRegisterDomain(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Site/getRegisterDomain?registration="+obj,headers);
  }

  getRetrieveTransferParameters(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Site/getretrieveTransferParameters?domainName="+obj,headers);
  }

  PostDomainRegistration(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Site/PostDomainRegistration", obj,headers);
  }

  PostTransferRegistration(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Site/PostTransferRegistration", obj,headers);
  }


}
