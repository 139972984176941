import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostNotificationPagination(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetSingal(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Notification/GetSingal/"+obj,headers);
  }

  PostAddNotification(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Notification/PostAddNotification", obj,headers);
  }

  PutUpdateNotification(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Notification/PutUpdateNotification", obj,headers);
  }

  DeleteNotification(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Notification/DeleteNotification", options);
  }

}
