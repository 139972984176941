import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostPaginationUrl(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetDesignationGroup() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/OfficerDesignation/GetDesignationGroup",headers);
  }

  GetAllDesignation() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/OfficerDesignation/GetAllDesignation",headers);
  }

  GetSingalDesignation(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/OfficerDesignation/GetSingalDesignation/"+obj,headers);
  }

  PostAddDesignation(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/OfficerDesignation/PostAddDesignation", obj,headers);
  }

  PutUpdateDesignation(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/OfficerDesignation/PutUpdateDesignation", obj,headers);
  }

  DeleteDesignation(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/OfficerDesignation/DeleteDesignation", options);
  }

}
