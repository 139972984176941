import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmagazineService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostPaginationUrl(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetAllCategory() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/EmagazinesLabel/GetAllCategory",headers);
  }

  GetSingalCategory(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/EmagazinesLabel/GetSingalCategory/"+obj,headers);
  }

  PostAddCategory(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/EmagazinesLabel/PostAddCategory", obj,headers);
  }

  PutUpdateCategory(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/EmagazinesLabel/PutUpdateCategory", obj,headers);
  }

  DeleteCategory(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/EmagazinesLabel/DeleteCategory", options);
  }

  GetAllEagleJournal(pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/EagleJournal/GetAllEagleJournal?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }

  GetAllEagleArchive(pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/EagleJournal/GetAllEagleArchive?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }
  
  GetEagleJournal(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/EagleJournal/GetEagleJournal/"+obj,headers);
  }

  PostApproveRestore(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/EagleJournal/PostApproveRestore", obj,headers);
  }

  PostAddDEagleJournal(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/EagleJournal/PostAddDEagleJournal", obj,headers);
  }

  PutUpdateDEagleJournal(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/EagleJournal/PutUpdateDEagleJournal", obj,headers);
  }

  DeleteEagleJournal(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/EagleJournal/DeleteEagleJournal", options);
  }
}
