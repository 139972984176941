import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout style="background-color: white;">
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
     
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {}
// <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>

// <nb-layout-header>
// <ngx-header style="display: unset;"></ngx-header>
// </nb-layout-header>


//         <ng-content select="nb-menu"></ng-content>
//       </nb-sidebar>
// <nb-layout-footer fixed>
//          <ngx-footer></ngx-footer>
//        </nb-layout-footer>


