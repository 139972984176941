import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LectureService {
  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  PostPaginationUrl(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

  GetAllLectureByMe(id:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lecture/GetAllLectureByMe?id="+id+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }
  GetAllLectureByOther(id:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lecture/GetAllLectureByOther?id="+id+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }

  GeLecture(id:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lecture/GetLecture/"+id,headers);
  }

  GetLectureVideo(id:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lecture/GetLectureVideo/"+id,headers);
  }

  GetLectureAllVideo(id:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lecture/GetLectureAllVideo/"+id,headers);
  }


  PostAddLecture(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lecture/PostAddLecture", obj,headers);
  }

  PutUpdateLecture(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Lecture/PutUpdateLecture", obj,headers);
  }

  PutUpdateLectureStatus(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Lecture/PutUpdateLectureStatus", obj,headers);
  }

  DeleteLecture(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Lecture/DeleteLecture", options);
  }


  PostDelVideo(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl+"api/Lecture/PostDelVideo", obj,options);
  }
  DeleteLectureImage(obj: any): Observable<any> { 
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    }; 
    return this.http.delete(this.baseUrl+"api/Lecture/DeleteLectureImage", options);
  }

  PostAddUpdateSubscription(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/LectureSubscription/PostAddUpdateSubscription", obj,headers);
  }

  PostDeleteVideo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lecture/PostDeleleVideo/"+obj,{},headers);
  }

  PostLectureVideo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lecture/PostLectureVideo", obj,headers);
  }

}
