import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DegreeService {

  
  _getHeaders() {
    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;
  
  PostPaginationUrl(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }
  
  GetSingleDegree(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GetSingleDegree/"+ obj,headers);
  }

   PostDegreeVideo(obj: any): Observable<any> {
    let headers = this._getHeaders();
     return this.http.post(this.baseUrl+"api/Degree/PostDegreeVideo", obj,headers);
  }

  PostDegree(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Degree/PostDegree?offset=" + offset, obj,headers);
  }

  PutDegree(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.put(this.baseUrl+"api/Degree/PutDegree?offset=" + offset, obj,headers);
  }

  DeleteDegree(obj: any): Observable<any> {
    let headers = this._getHeaders();  
    return this.http.delete(this.baseUrl+"api/Degree/DeleteDegree/"+ obj,headers);
  }

  DeleteDegreeVideo(obj: any): Observable<any> {
    let headers = this._getHeaders();  
    return this.http.delete(this.baseUrl+"api/Degree/DeleteDegreeVideo/"+ obj,headers);
  }

  PostDegreeStatusUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Degree/PostDegreeStatusUpdate", obj,headers);
  }

  PutDegreeSechedule(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Degree/PutDegreeSechedule", obj,headers);
  }
}
