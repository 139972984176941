import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SearchendpointService {

  search_key = localStorage.getItem("AdminOlympus_Search_key");

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;
  list:[];

  SearchApi(table_name: any,queryBy: any,filterBy: any,q: any): Observable<any> {
    var url = "";
    // if(filterBy == "" || filterBy == null){
    //   url = "https://search.endpoints.olympus-317810.cloud.goog/collections/"+table_name+"/documents/search?query_by="+queryBy+"&q="+q;
    // }
    // else{
    //   url = "https://search.endpoints.olympus-317810.cloud.goog/collections/"+table_name+"/documents/search?query_by="+queryBy+"&filter_by="+filterBy+"&q="+q;
    // }

    if(filterBy == "" || filterBy == null){
        url = "https://search.olympusweb.io/collections/"+table_name+"/documents/search?query_by="+queryBy+"&q="+q;
      }
      else{
        url = "https://search.olympusweb.io/collections/"+table_name+"/documents/search?query_by="+queryBy+"&filter_by="+filterBy+"&q="+q;
      }
    
      //'X-TYPESENSE-API-KEY' : '94qhgmnBln',
    let httpHeadersSearch = new HttpHeaders({
      'Content-Type' : 'application/json; charset=utf-8',
      'X-TYPESENSE-API-KEY' : this.search_key,
    }).append('Access-Control-Allow-Origin', '*');
    let optionsSearch = {
      headers: httpHeadersSearch
    };
    //this.searchendpointService.SearchApi(table_name,queryBy,q).subscribe(
      //     datares => {
      //   });
    var list = null;  
    return this.http.get(url,optionsSearch);
        
        
  }

  
}
