import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent } from '@nebular/auth';
import {SiteinfoService} from '../service/siteinfo.service';
import {ProfileService} from '../service/profile.service';
import {ToasterService} from '../service/toaster.service';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  email = '';
  password = '';
  organization = '';
  first_name = '';
  last_name = '';
  agree = false;

  btn_name = "Submit";

  constructor(private ToasterService: ToasterService,private router: Router,private SiteinfoService:SiteinfoService,private ProfileService:ProfileService) {
    if(window.localStorage.getItem('AdminOlympusToken')) {
      this.router.navigate(['/admin/graphs']);
    }
  }

  Submit(){
    this.btn_name = "Processing...";
    if(this.agree == true){
      var offset = (new Date).toLocaleString('en-US', {
        hour12: false,
        hour: '2-digit',
        timeZoneName: 'long'
      }).replace(/^\d\d /, '');
  
    this.ProfileService.fetchSignInMethodsForEmail(this.email).then(
      (data) => {
        // success, show some message
        if(data.length == 0){
          // this.ProfileService.FireaseBaseAuthRegister(this.email,this.password).then(
          //   (datareg) => {
          //      var fid: any = datareg.user.uid;
               var _id = this.organization;
               _id = _id.trim().replace(',','').replace(/\s+/g, '-').toLowerCase();
       
               const body = new HttpParams()
               .set('id', _id)
              // .set('uid', fid)
               .set('name', this.organization)
               .set('organization', "Other")
               .set('fname', this.first_name)
               .set('lname', this.last_name)
               .set('email', this.email)
               .set('password', this.password);
       
               this.SiteinfoService.PostSite(body).subscribe(
                 datasignup => {
                   if(datasignup == 1){
                      /** sends verification email **/ 
                    // datareg.user.sendEmailVerification();
                     this.ToasterService.showToast('success', 'SignUp', "Organization Registered Successfully");
                     this.router.navigate(['/auth/login']);
                   }
                   else if(datasignup == 2){
                    // datareg.user.delete();
                     this.ToasterService.showToast('warning', 'SignUp', "Organization Already Registered");
                     this.btn_name = "Submit";
                   }
                   else{
                    // datareg.user.delete();
                     this.ToasterService.showToast('warning', 'SignUp', "Something wrong.....  please try again");
                     this.btn_name = "Submit";
                   }
               });
          // },
          // err => {
          //   this.btn_name = "Submit";
          //   this.ToasterService.showToast('warning', 'SignUp', "Something wrong.....  please try again");
          //   // handle errors
          // });
  
          
          
        }
        else{
          this.ToasterService.showToast('warning', 'SignUp', "Email Address Already Registered");
          this.btn_name = "Submit";
        }
      },
      err => {
        this.ToasterService.showToast('warning', 'SignUp', err.message);
        this.btn_name = "Submit";
        // handle errors
      });
    }
    else{
      this.ToasterService.showToast('warning', 'SignUp', "Please I agree with Terms of Service and Privacy Policy");
      this.btn_name = "Submit";
    }
  }

  ngOnInit(): void{
   
  }

}
