import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded'
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient,public afAuth: AngularFireAuth) { }
  baseUrl: string = environment.ServerUrl;
  private extractData(res: Response) {
    let body = res.json();
    return body || { };
  }
  
  doForgotPassword(email){
    return this.afAuth.sendPasswordResetEmail(email);
  }

  doVerifyPassword(code){
    return this.afAuth.verifyPasswordResetCode(code);
  }

  doVerifyEmail(code){
    
  }

  doChangePassword(code,password){
    return this.afAuth.confirmPasswordReset(code,password);
  }

  FireaseBaseAuthRegister(email, password){
   return this.afAuth.createUserWithEmailAndPassword(email,password);
  }

  FireaseBaseAuthSignIn(email, password){
    return this.afAuth.signInWithEmailAndPassword(email,password);
  }

  fetchSignInMethodsForEmail(email){
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  sendSignInLinkToEmail(email){

    //https://localhost:4200/login
    //valleyrvc.org

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'https://localhost:4200/login',
      // This must be true.
      handleCodeInApp: true,
    }
    return this.afAuth.sendSignInLinkToEmail(email,actionCodeSettings);
  }

  UserSearch(obj: any): Observable<any> {
    const body = new HttpParams()
    .set('Prefix', obj);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"app/ProfilePage/PostUserSearch", body,headers)
  }

  GetConfigdashboard() : Observable<any> {
    return this.http.get(this.baseUrl+"api/AppConfig/GetConfigdashboard",{responseType: 'json'});
  }

  UserNameSearch(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"app/ProfilePage/PostNameSearch", obj,headers);
  }

  GetUserDetail(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/GetUserDetail", obj,headers);
  }
  GetlistUser() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetlistUser",headers);
  }
  GetlistOrganizer() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetOrganizerlist",headers);
  }

  GetUserEmail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Site/GetUserEmail?email=" + obj,headers);
  }

  GetAllUser() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetAllUser",headers);
  }
  PostUserLodgeUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserLodgeUpdate", obj,headers);
  }
  PostUserAccountActiveSponsorship(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/User/PostUserAccountActiveSponsorship?offset=" + offset, obj,headers);
  }
  PostUserAccountDisableSponsorship(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserAccountDisableSponsorship", obj,headers);
  }
  PostUserAccountunregister(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserAccountunregister", obj,headers);
  }
  PostUserApprovalStatus(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserApprovalStatus", obj,headers);
  }
  PostUserMemberIdUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserMemberIdUpdate", obj,headers);
  }
  PostUserSignupByDashboard(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/SignUp/PostUserSignupByDashboard", obj,headers);
  }

  // PostUserSignup(obj: any): Observable<any> {
  //   let headers = this._getHeaders();
  //   var offset = new Date().getTimezoneOffset();
  //   return this.http.post(this.baseUrl+"api/SignUp/PostUserSignup?offset=" + offset, obj,headers);
  // }

  GetNameDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetNameDetail?email="+obj,headers);
  }

  Login(obj: any): Observable<any> {
    let headers = this._getHeaders();
    const body = new HttpParams()
    .set('Email', obj.Email)
    .set('Password', obj.Password);
    return this.http.post(this.baseUrl+"api/Account/PostLogin", body,headers);
  }

  token(obj: any): Observable<any> {
    let headers = this._getHeaders();
    const body = new HttpParams()
    .set('username', obj.Email)
    .set('password', obj.Password);

    return this.http.post(this.baseUrl+"api/admintoken", body,headers);
  }

  tokenfull(obj: any, _site:any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    const body = new HttpParams()
    .set('username', obj.Email)
    .set('password', obj.Password)
    .set('token', obj.FirebaseUserId);

    return this.http.post(this.baseUrl+"api/AdminToken/CreateToken?offset=" + offset+"&site=" +_site, body,headers);
  }

  LoginResponse(obj: any): Observable<any> {
    let headers = this._getHeaders();
    
    const body = new HttpParams()
    .set('TimeZone', obj.TimeZone)
    .set('accesstoken', obj.accesstoken)
    .set('expire', obj.expire)
    .set('id', obj.id);
    return this.http.post(this.baseUrl+"api/Account/PostLoginRes", body,headers);
  }

  
  getValidation(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Account/getValidation/"+obj,headers);
  }
  getToken(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Account/getToken/"+obj,headers);
  }

  getUserApprovalPendingCount() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetUserApprovalPendingCount",headers);
  }

  DeleteUser(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/User/DeleteUser", options);
  }

  PostUserPagination(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }
}
