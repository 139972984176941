import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DonationOptionService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetCharities() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/DonationSetting/GetCharities",headers);
  }

  GetSingleCharities(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/DonationSetting/GetSingleCharities/"+obj,headers);
  }

  GetPredefinedAmount() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/DonationSetting/GetPredefinedAmount",headers);
  }

  GetSinglePredefinedAmount(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/DonationSetting/GetSinglePredefinedAmount/"+obj,headers);
  }

  PostCharitie(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/DonationSetting/PostCharitie", obj,headers);
  }

  PostPredefinedAmount(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/DonationSetting/PostPredefinedAmount", obj,headers);
  }

  PutCharitie(obj: any): Observable<any> {
    let headers = this._getHeaders();
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put(this.baseUrl+"api/DonationSetting/PutCharitie", obj,headers);
  }

  PutPredefinedAmount(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/DonationSetting/PutPredefinedAmount", obj,headers);
  }

  DeleteCharitie(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/DonationSetting/DeleteCharitie/"+obj,headers);
  }

  DeletePredefinedAmount(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/DonationSetting/DeletePredefinedAmount/"+ obj,headers);
  }
}
