import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SettingService {


  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetGraph() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetGraph",headers);
  }

  GetGraphPaymenyBy(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetGraphPaymenyBy?pby="+obj,headers);
  }

  GetSetting(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetSetting/" + obj,headers);
  }

  GetPaymentall(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentall/" + obj,headers);
  }

  GetPaymentDONATE(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentDONATE/" + obj,headers);
  }

  GetCashRegister(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetCashRegister/" + obj,headers);
  }

  GetPaymentinfo(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentinfo/" + obj + "?offset=" + offset,headers);
  }

  GetPaymentPending(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentPending/" + obj,headers);
  }

  GetPlan() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPlan",headers);
  }

  GetPaymentSetting() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentSetting",headers);
  }

  PutUpdatePaymentSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdatePaymentSetting", obj,headers);
  }

  PostAddSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Setting/PostAddSetting", obj,headers);
  }

  //Delete UpdateCardInformation
  PutRemoveCardinfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutRemoveCardinfo", obj,headers);
  }

  //Update UpdateCardInformation
  UpdateCardInformation(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateCardinfo", obj,headers);
  }

  //Update UpdateCardInformation
  PutUpdateSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateSetting", obj,headers);
  }

  GetCustomerBillingInfo(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetCustomerBillingInfo/" + obj,headers);
  }

  PutUpdateBilinginfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateBilinginfo", obj,headers);
  }

  InsertUpdateManuallyPaymentSaveMaster(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/InsertUpdateManuallyPaymentSaveMaster", obj,headers);
  }

  GetlistUser() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetlistUser",headers);
  }

  GetlistUserContect() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetlistUserContect",headers);
  }

  GetUserlistfordrop() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetUserlistfordrop",headers);
  }

  GetAllPayNowWithEvent() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PayNowMaster/GetAllPayNowWithEvent",headers);
  }

  
  GetPlanPriceinfo(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetPlanPriceinfo/" + obj,headers);
  }

  PutUpdatePlanPrice(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdatePlanPrice", obj,headers);
  }

  GetAllVirtualMeeting() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetAllVirtualMeeting",headers);
  }

  GetVirtualMeeting() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetVirtualMeeting",headers);
  }

  InsertUpdateVirtualMeeting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Setting/InsertUpdateVirtualMeeting", obj,headers);
  }

  GetSocialMediaSetting() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Setting/GetSocialMediaSetting",headers);
  }

  PutUpdateSocialMediaSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateSocialMediaSetting", obj,headers);
  }



}
