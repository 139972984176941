import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {ProfileService} from '../service/profile.service';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

  userid = "";
  token = "";
  role = "";
  _access = false;
  constructor(private _router: Router,private ProfileService:ProfileService) {
  }

  onLogOut(){
    localStorage.removeItem("AdminOlympusToken");
    localStorage.removeItem("AdminOlympus_FirstName");
    localStorage.removeItem("AdminOlympus_LastName");
    localStorage.removeItem("AdminOlympus_LodgeId");
    localStorage.removeItem("AdminOlympus_SubLodgeId");
    localStorage.removeItem("AdminOlympus_UserId");
    localStorage.removeItem("AdminOlympus_Email");
    localStorage.removeItem("AdminOlympus_UserImage");
    localStorage.removeItem("AdminOlympus_Role_id");
    localStorage.removeItem("AdminOlympus_GoogleStoragePath");
    localStorage.removeItem("AdminOlympus_Site");
    localStorage.removeItem("AdminOlympus_ConfigValid");
    localStorage.removeItem("AdminOlympus_Roles");
    this._router.navigate(['/auth/login']);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //state.url  //get url
    var that = this;
    this._access = false;
     if(window.localStorage.getItem('AdminOlympus_Roles')) {
      this.role = window.localStorage.getItem('AdminOlympus_Roles');
      if(this.role == "" || this.role == null){
        this._access = true;
      }
      else{
        var splitrole = this.role.split(',');
        if(route.data.roles != undefined){
          
          splitrole.forEach(function (value) {
            var obj = route.data.roles.find(x => x === value);
            if(obj != undefined){
              that._access = true;
            }
          });
        }
        

      }
      
    }
    else{
      this._access = true;
    }

    if(this._access == false){
      this.onLogOut();
    }

    return this._access;
    
  }

}