import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorinterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                localStorage.removeItem("AdminOlympusToken");
                localStorage.removeItem("AdminOlympus_FirstName");
                localStorage.removeItem("AdminOlympus_LastName");
                localStorage.removeItem("AdminOlympus_LodgeId");
                localStorage.removeItem("AdminOlympus_SubLodgeId");
                localStorage.removeItem("AdminOlympus_UserId");
                localStorage.removeItem("AdminOlympus_Email");
                localStorage.removeItem("AdminOlympus_UserImage");
                localStorage.removeItem("AdminOlympus_Role_id");
                localStorage.removeItem("AdminOlympus_GoogleStoragePath");
                localStorage.removeItem("AdminOlympus_Site");
                localStorage.removeItem("AdminOlympus_ConfigValid");
                localStorage.removeItem("AdminOlympus_Roles");
                // auto logout if 401 response returned from api
                this.router.navigate(['/auth/login']);
            }

           // const error = err.error.message || err.statusText;
            const error = err.statusText;
            return throwError(error);
        }))
    }
}