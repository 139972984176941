import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent } from '@nebular/auth';
import {ProfileService} from '../service/profile.service';
import {ToasterService} from '../service/toaster.service';

@Component({
  selector: 'ngx-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  email = '';
  
  constructor(private ToasterService: ToasterService,private router: Router,private ProfileService:ProfileService) {
    if(window.localStorage.getItem('AdminOlympusToken')) {
      window.location.href = "/admin/graphs";
    }
  }

   isValidEmailAddress(emailAddress) {
    const pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  }

  SubmitClick(){
    let status = false;
    let stringEmail = false;
    if (this.email.indexOf('@') >= 0) {
            stringEmail = true;
            if (this.isValidEmailAddress(this.email)) {
                status = true;
            }
            else {
                this.ToasterService.showToast('warning', 'Email', 'Please enter a valid email address.');
                status = false;
            }
        }
    else {
        status = true;
    }
    if (status) {
      this.ProfileService.doForgotPassword(this.email).then(
        (data) => {
          // success, show some message
          this.ToasterService.showToast('success', 'Forgot Password', 'Reset password email is sent. Please check your inbox');
        },
        err => {
          this.ToasterService.showToast('warning', 'Forgot Password', err.message);
     
          // handle errors
        });
    }
  }

  ngOnInit() {
  }

}
