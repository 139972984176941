import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
var CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  _getHeaders() {
    // let httpHeaders = new HttpHeaders({
    //   'Content-Type' : 'application/x-www-form-urlencoded'
    // });
    // "X-Forwarded-Proto": "http/2"
    let httpHeaders = new HttpHeaders({
        "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  uploadFileToUrlString(t: any,n:any): Observable<any> {
    
    const formData = new FormData();
    formData.append('file', t);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  EventuploadFileToUrlString(t: any,n:any,id:any): Observable<any> {
    
    const formData = new FormData();
    formData.append('file', t);
    formData.append('eventid', id);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  uploadFileToUrl(t: any,n:any): Observable<any> {
    
    const formData = new FormData();
    formData.append('file', t,t.name);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  SlideruploadFileToUrl(t: any,n:any,_poster:any): Observable<any> {
    
    const formData = new FormData();
    formData.append('file', t,t.name);
    formData.append('poster',_poster);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  BloguploadFileToUrl(t: any,n:any,_poster:any): Observable<any> {
    
    const formData = new FormData();
    formData.append('file', t,t.name);
    formData.append('poster',_poster);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  uploadFileWithGallery(t: any,e:any,i:any,text:any,up:any,r:any,display_home:any,display_public:any,_poster:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
      formData.append('poster',_poster);
      formData.append('EventId',e);
      formData.append('UserId',i);
      formData.append('file_text',text);
      formData.append('UploadType',up);
      formData.append('display_home',display_home.toString());
      formData.append('display_public',display_public.toString());
    let headers = this._getHeaders();
    return this.http.post(r,formData,headers);
  }

  
  uploadFileWithUserIdToUrl(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t,t.name);
      formData.append('userid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  
  uploadEventDocumentWithEventId(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t,t.name);
      formData.append('eventid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  
  uploadUploadValleyScottishRite(t: any,n:any,_poster: any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t,t.name);
      formData.append('poster',_poster);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  
  uploadLodgeWithLodgeId(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t,t.name);
      formData.append('lodgeid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  UploadLecture(obj: any,objParam: any): Observable<any> {
     const formData = new FormData();
     formData.append('file',obj,obj.name);
     let headers = this._getHeaders();
     //return this.http.post(this.baseUrl+"api/Lecture/UploadLectureVideo",formData);
     return this.http.post(this.baseUrl+"app/FileUpload/UploadLecture"+objParam,formData,headers);
  }

  UploadDegreeVideo(obj: any,userid: any,degreeId: any,_poster: any): Observable<any> {
     const formData = new FormData();
     formData.append('file',obj,obj.name);
     formData.append('poster',_poster);
     formData.append('UserId',userid);
     formData.append('DegreeId',degreeId);
     let headers = this._getHeaders();
     return this.http.post(this.baseUrl+"api/Degree/UploadDegreeVideo",formData,headers);
  }

  UploadMaxFile(objfile: any, _path:any): Observable<any> {
    var url = "https://upload-x7bsi3dmrq-uk.a.run.app/upload";
    var _site = window.localStorage.getItem('AdminOlympus_Site');
    const date = new Date().toUTCString();
    var hash = CryptoJS.HmacSHA256("x-date: " + date, environment.Auth_Secret);
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    //var _auth_header= "Signature keyId='"+environment.Auth_Key+"',algorithm='hmac-sha256',signature='"+hashInBase64+"'";
    var _auth_header= 'Signature keyId="'+environment.Auth_Key+'",algorithm="hmac-sha256",headers="x-date",signature="'+hashInBase64+'"';

    let httpHeaders = new HttpHeaders({
      "Cache-Control": "no-cache",
      "Access-Control-Expose-Headers":"Content-Disposition",
      "Accept": "application/json",
      "Authorization": _auth_header,
      "x-date": date,
      "Last-Modified": date
     });

    let options = {
      headers: httpHeaders
    };

    var timestamp = new Date().getTime();
    var filename = objfile.name;
    var name_arr = filename.split('.');
    var new_file_name = timestamp + "." + name_arr[1];

    const formData = new FormData();
    formData.append('file', objfile,new_file_name);
    formData.append('path', _path);
    formData.append('site', _site);
    return this.http.post(url,formData,options);
  }

  UploadHttp2www(t: any,e:any,i:any,text:any,up:any,r:any): Observable<any> {
    var _token = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImQwMWMxYWJlMjQ5MjY5ZjcyZWY3Y2EyNjEzYTg2YzlmMDVlNTk1NjciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMjU1NTk0MDU1OS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMyNTU1OTQwNTU5LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA5OTM1MjQ2ODg1MDA0ODU3ODU3IiwiaGQiOiJibHVlcG9ydGFsLmlvIiwiZW1haWwiOiJpbmZvQGJsdWVwb3J0YWwuaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImE0WUZ5WlFjUWdvQzZCZGhnTGV3TkEiLCJpYXQiOjE2NDEyMjkyMjYsImV4cCI6MTY0MTIzMjgyNn0.gKP9KjOE3MRcDdlC2d0CRjNMDVr5hAACLSX97vpb8TmWqGGAhgplzVqzKEg8MaTZ6iQCNgixgAMsXeKIGAp0VtsIceDgQIQBnauHXr2vzltLqGq_-AhA8mY6SIuWnIbhuzLxesn_1nGjlcWhMLbbK-FFQ1rc9RxYTb5tjXEyrM_HUatyxdOTMDIWtMHIsgWChNxOarblzKITkT8yQzLLFVcxLVrkwq5Eg-8NaUrhtKu7C3v_JLAKcI1Un3Beh4SxXaFVa9osOJ3ah9zl_GHxhCZqgjwAKJMT-_ucOyjaEgC9rbb0zAq1AQn_XPIfvsKDf5ZpPQvUVtNg4dM6KFcmdw";
    var url = "https://upload-x7bsi3dmrq-uk.a.run.app/upload";

   
    let httpHeaders = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": "Bearer "+_token
     });

    let options = {
      headers: httpHeaders
    };


    const formData = new FormData();
    formData.append('file', t,t.name);
    return this.http.post(url,formData,options);
  }

  UploadHttp2(t: any,e:any,i:any,text:any,up:any,r:any): Observable<any> {

// "X-Forwarded-Proto": "http/2"
     // let httpHeaders = new HttpHeaders({
    //   'Content-Type' : 'multipart/form-data',
    //   "Accept": "application/json",
    //   "Authorization": "Bearer "+_token,
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'POST,GET',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    //   'Access-Control-Max-Age': '3600'
    // });


    //var _site = window.localStorage.getItem('AdminOlympus_Site');
    //let _token = window.localStorage.getItem('AdminOlympusToken');
 
    var _token = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImQwMWMxYWJlMjQ5MjY5ZjcyZWY3Y2EyNjEzYTg2YzlmMDVlNTk1NjciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMjU1NTk0MDU1OS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMyNTU1OTQwNTU5LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA5OTM1MjQ2ODg1MDA0ODU3ODU3IiwiaGQiOiJibHVlcG9ydGFsLmlvIiwiZW1haWwiOiJpbmZvQGJsdWVwb3J0YWwuaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImE0WUZ5WlFjUWdvQzZCZGhnTGV3TkEiLCJpYXQiOjE2NDEyMjkyMjYsImV4cCI6MTY0MTIzMjgyNn0.gKP9KjOE3MRcDdlC2d0CRjNMDVr5hAACLSX97vpb8TmWqGGAhgplzVqzKEg8MaTZ6iQCNgixgAMsXeKIGAp0VtsIceDgQIQBnauHXr2vzltLqGq_-AhA8mY6SIuWnIbhuzLxesn_1nGjlcWhMLbbK-FFQ1rc9RxYTb5tjXEyrM_HUatyxdOTMDIWtMHIsgWChNxOarblzKITkT8yQzLLFVcxLVrkwq5Eg-8NaUrhtKu7C3v_JLAKcI1Un3Beh4SxXaFVa9osOJ3ah9zl_GHxhCZqgjwAKJMT-_ucOyjaEgC9rbb0zAq1AQn_XPIfvsKDf5ZpPQvUVtNg4dM6KFcmdw";
    var url = "https://upload-x7bsi3dmrq-uk.a.run.app/upload";

     const formData = new FormData();
       formData.append('file', t);
       // 'Content-Type' : 'application/x-www-form-urlencoded'
 
      // var url = "https://upload-x7bsi3dmrq-uk.a.run.app/upload";


 
       var xhr = new XMLHttpRequest();
       xhr.open("POST", url);
 
       //xhr.setRequestHeader("Authorization", "Bearer $(gcloud auth print-identity-token)");
      // xhr.setRequestHeader("Content-Type", "multipart/form-data");
       xhr.setRequestHeader("X-Forwarded-Proto", "http/2");
       //xhr.setRequestHeader("Content-Type", "file");
       xhr.setRequestHeader("Authorization", "Bearer "+_token);
       //xhr.setRequestHeader("Content-Length", "0");
 
       xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
             
         }};
       xhr.send(formData)
       return xhr.response;
   }

  UploadHttp2_yyy(t: any,e:any,i:any,text:any,up:any,r:any): Observable<any> {

   var _site = window.localStorage.getItem('AdminOlympus_Site');
   let _token = window.localStorage.getItem('AdminOlympusToken');

    const formData = new FormData();
      formData.append('file', t);
      formData.append('EventId',e);
      formData.append('UserId',i);
      formData.append('file_text',text);
      formData.append('UploadType',up);
    

      var url = r+"&XMLHttpRequest=true";

      var xhr = new XMLHttpRequest();
      xhr.open("POST", url);

      //xhr.setRequestHeader("Authorization", "Bearer $(gcloud auth print-identity-token)");
      xhr.setRequestHeader("Authorization", "Bearer "+_token);
      xhr.setRequestHeader("Content-Length", "0");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            
        }};
      xhr.send(formData)
      return xhr.response;
  }

}
