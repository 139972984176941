import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'truncatetext'
})

export class TruncateTextPipe implements PipeTransform {

transform(value: string, length: number = 5, trail: String = '…'): string {
   let result = value || '';

   if (value != null) {
      const words = value.split(/\s+/);
     if (words.length > Math.abs(length)) {
       if (length < 0) {
        length *= -1;
         result =
           trail + words.slice(words.length - length, words.length).join(' ');
       } else {
         result = words.slice(0, length).join(' ') + trail;
       }
     }
   }

   return result;
 }
}