import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  _getHeadersAutho() {
    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
    let httpHeaders = new HttpHeaders({
      'Authorization' : 'Bearer ' + myItem
      });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  SampleOutput(obj:any) : Observable<any> {
    return this.http.get("http://localhost:32768/api/Application/SampleOutput/"+obj,{responseType: 'json'});
  }

  _getHeaders() {
    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetCustomerEmailResponse(event_id:any,customer_id:any,emailid:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetCustomerEmailResponse/"+event_id + "/" + customer_id+"?email="+emailid,headers);
  }

  GetAllPastEventDash(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAllPastEventDash/"+obj,headers);
  }

  GetAllUpComingEventDash() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAllUpComingEventDash",headers);
  }

  PostAddEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventVideoStream", obj,headers);
  }

  PostUpdateEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEventVideoStream", obj,headers);
  }

  PostDelEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostDelEventVideoStream", obj,headers);
  }

  PostAddEventLabel(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventLabel", obj,headers);
  }

  PostUpdateEventLabel(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEventLabel", obj,headers);
  }

  DeleteEventLabel(obj: any): Observable<any> {  
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventLabel/"+ obj,headers);
  }

  PostAddEventDresscode(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventDresscode", obj,headers);
  }

  DeleteEventDresscode(obj: any): Observable<any> {  
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventDresscode/"+ obj,headers);
  }


  // EventSearch(obj:any) : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.post(this.baseUrl+"app/EventPage/PostEventSearch",obj,headers);
  // }

  GetEventinfoSend(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventinfoSend/"+ obj,headers);
  }

  GetComingEventAttendMemberlist(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetComingEventAttendMemberlist/"+ obj,headers);
  }

  GetSingleGallery(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetSingleGallery/"+ obj,headers);
  }

  PostGalleryTitle(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostGalleryTitle", obj,headers);
  }

  PostUpdateGallery(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateGallery", obj,headers);
  }

  PostRecipientSend(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostRecipientSend", obj,headers);
  }

  GetEventCheckIn(eid: any,uid:any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventCheckIn/"+ eid +"?uid="+uid,headers);
  }

  GetEventBarCode(eid: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventBarCode/"+ eid,headers);
  }


  GetAteendiPendingUserlist(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.get(this.baseUrl+"api/Event/GetAteendiPendingUserlist/"+ obj+"?offset="+offset,headers);
  }

  GetAteendiPendingByUser(obj: any,uid:any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.get(this.baseUrl+"api/Event/GetAteendiPendingByUser/"+ obj+"?uid="+uid+"&offset="+offset,headers);
  }

  GetAll_Event(): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAll_Event",headers);
  }

  // GetAllEvents(lodgeid:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.get(this.baseUrl+"api/Event/GetAllEvent?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  // }

  // GetAllPastEvent(lodgeid:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.get(this.baseUrl+"api/Event/GetAllPastEvent?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  // }
  
  TestApi(): Observable<any> {
    const body = new HttpParams()
    .set('name','krimesh');

    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
    
    let headers = new HttpHeaders();
    headers.append("Authorization", "Bearer " + myItem);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Access-Control-Allow-Origin", "*");

    return this.http.post(this.baseUrl+"api/Event/PostTest",body,{headers: headers});
  }

  GetAllUpComingEvent(lodgeid:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
   return this.http.get(this.baseUrl+"api/Event/GetAllUpComingEvent/"+lodgeid+"?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }

  // GetEvent() : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.get(this.baseUrl+"api/Event/GetEvent",headers);
  // }

  GetAllEvent(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAllPastEvent/"+obj,headers);
  }

  GetLodgeAEvent() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetLodgeAEvent",headers);
  }

  GetFetchEventInfo(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.get(this.baseUrl+"api/Event/GetFetchEventInfo/"+obj + "?offset=" + offset,headers);
  }

  GetFetchSingleEvent(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetFetchSingleEvent/"+obj,headers);
  }


  PostEventAttendSingle(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostEventAttendSingle", obj,headers);
  }

  PostEventGallery(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostEventGallery", obj,headers);
  }

  PostAddEvent(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Event/PostAddEvent?offset=" + offset, obj,headers);
  }

  //Update EventId User
  PostAdUpdatedEventIdUser(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAdUpdatedEventIdUser", obj,headers);
  }

  //Update Event
  PostUpdateEvent(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEvent?offset=" + offset, obj,headers);
  }

  //My Get Event Gallery
  GetMyALLEventImage(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/GetMyALLEventImage",obj,headers);
  }

  //My Get All Event Image
  GetMyALLEventGallery(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/GetMyALLEventGallery",obj,headers);
  }

  //Get Event Gallery
  GetEventGallery(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventGallery/"+obj,headers);
  }

  GetTitle(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetTitle/"+obj,headers);
  }

  //Get All Event Image
  GetAllEventImage(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAllEventImage/"+obj,headers);
  }

  DeleteEvent(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEvent", options);
  }

  DeleteEventGallery(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventGallery", options);
  }

  DeleteEventDocument(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventDocument", options);
  }


  //Get GetAllEventAttendlist 
  GetAllEventAttendlist(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetAllEventAttendlist/"+obj,headers);
  }

  //Delete Event Attend
  DeleteEventAttened(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventAttened", options);
  }

  DeleteEventCheckIn(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventCheckIn", options);
  }

  GetSingalEventAttend(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetSingalEventAttend/"+obj,headers);
  }

  PostAddChekin(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddChekin", obj,headers);
  }

  PostAddEventAttened(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventAttenedDes", obj,headers);
  }

  PutEventAttened(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Event/PutEventAttened", obj,headers);
  }

  GetImageRotate(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetImageRotate/"+obj,headers);
  }

  GetImageRotateBack(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetImageRotateBack/"+obj,headers);
  }

  PostEventPagination(_url: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(_url, {},headers);
  }

}
