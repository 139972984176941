import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import {environment} from "../../../../environments/environment";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

type menuList = {menu: string, name: string, link: any};

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('inputsearch', {static: false}) inputsearch: ElementRef;

  baseUrl: string = environment.ServerUrl;
  //googleStoragePath: string = window.localStorage.getItem('AdminOlympus_GoogleStoragePath');
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  firstname = localStorage.getItem("AdminOlympus_FirstName");
  lastname = localStorage.getItem("AdminOlympus_LastName");
  image = window.localStorage.getItem('AdminOlympus_UserImage');
  name = this.firstname + ' ' + this.lastname
  firstchar = '';
  user: any;
  chkimg = false;
  imgurl = '';

  searchname:any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out'} ];

  public model: menuList;

  formatter = (state: menuList) => state.name;

  search: OperatorFunction<string, readonly {menu, name, link}[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.searchlist.filter(state => new RegExp(term, 'mi').test(state.menu + " " + state.name)).slice(0, 10))
  )

  selectedItem($e,input) {
    $e.preventDefault();
    if($e.item != null){
      this._router.navigate([$e.item.link])
    }
  }

  searchlist = [
    {
      menu:'Analytics',
      name: 'Analytics',
      link: '/admin/graphs'
    },
    {
      menu:'Notifications',
      name: 'Notifications',
      link: '/admin/settings/pushnotifications'
    },
    {
      menu:'Announcements',
      name: 'Announcements',
      link: '/admin/settings/notifications'
    },
    {
      menu:'Store',
      name: 'Products',
      link: '/admin/settings/cashregisteroptions'
    },
    {
      menu:'Store',
      name: 'Categories',
      link: '/admin/settings/categorystore'
    },
    {
      menu:'Officers',
      name: 'Officers',
      link: '/admin/valleyofficer/list'
    },
    {
      menu:'Officers',
      name: 'Titles',
      link: '/admin/settings/designation'
    },
    {
      menu:'Officers',
      name: 'Rites',
      link: '/admin/settings/designationgroups'
    },
    {
      menu:'Officers',
      name: 'Jurisdictions',
      link: '/admin/settings/supremecouncil'
    },
    {
      menu:'Events',
      name: 'Events',
      link: '/admin/events/eventlist'
    },
    {
      menu:'Events',
      name: 'Dress Code',
      link: '/admin/events/dresscode'
    },
    {
      menu:'Events',
      name: 'RSVP Option',
      link: '/admin/events/rsvp'
    },
    {
      menu:'Events',
      name: 'Vertual Meeting',
      link: '/admin/settings/virtualmeeting'
    },
    {
      menu:'Emails',
      name: 'Campaigns',
      link: '/admin/emails/campaignlist'
    },
    {
      menu:'Emails',
      name: 'Contacts',
      link: '/admin/emails/contactlist'
    },
    {
      menu:'Emails',
      name: 'Lists',
      link: '/admin/emails/distributionlist'
    },
    {
      menu:'Emails',
      name: 'Archives',
      link: '/admin/emails/archivelist'
    },
    {
      menu:'Business',
      name: 'Categories',
      link: '/admin/business/businesscategorys'
    },
    {
      menu:'Blog',
      name: 'Blog',
      link: '/admin/resources/lectures'
    },
    {
      menu:'Blog',
      name: 'Categories',
      link: '/admin/resources/lecturecategorys'
    },
    {
      menu:'Blog',
      name: 'Subscriptions',
      link: '/admin/resources/lecturesubscription'
    },
    {
      menu:'Dues',
      name: 'Dues Transactions',
      link: '/admin/settings/paymentlist'
    },
    {
      menu:'Dues',
      name: 'Dues Amount',
      link: '/admin/settings/planprice'
    },
    {
      menu:'Dues',
      name: 'Processing Date',
      link: '/admin/settings/paymentdate'
    },
    {
      menu:'Donations',
      name: 'Charities',
      link: '/admin/settings/charitiesoption'
    },
    {
      menu:'Donations',
      name: 'Charities Amount',
      link: '/admin/settings/predefinedamountoption'
    },
    {
      menu:'Media',
      name: 'Gallery',
      link: '/admin/gallerys/list'
    },
    {
      menu:'Users',
      name: 'Users',
      link: '/admin/settings/users'
    },
    {
      menu:'Users',
      name: 'Groups',
      link: '/admin/settings/groups'
    },
    {
      menu:'Users',
      name: 'Administrators',
      link: '/admin/settings/lodgeeditor'
    },
    {
      menu:'Users',
      name: 'Datatbase',
      link: '/admin/settings/database'
    },
    {
      menu:'Degrees',
      name: 'Content',
      link: '/admin/resources/content'
    },
    {
      menu:'Degrees',
      name: 'Audience',
      link: '/admin/resources/audience'
    },
    {
      menu:'Settings',
      name: 'Home',
      link: '/admin/settings/homepageslider'
    },
    {
      menu:'Settings',
      name: 'Logo',
      link: '/admin/settings/logo'
    },
    {
      menu:'Settings',
      name: 'Faveico',
      link: '/admin/settings/faveico'
    },
    {
      menu:'Settings',
      name: 'Social',
      link: '/admin/settings/socialmedialink'
    },
    {
      menu:'Settings',
      name: 'Privacy & Terms',
      link: '/admin/settings/usersetting'
    },
    {
      menu:'Settings',
      name: 'About',
      link: '/admin/settings/scottishrite'
    },
    {
      menu:'Settings',
      name: 'Contact',
      link: '/admin/settings/valleyinfo'
    },
    {
      menu:'Settings',
      name: 'Config',
      link: '/admin/settings/app-config'
    }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              public afAuth: AngularFireAuth,
              private breakpointService: NbMediaBreakpointsService,
              private _router: Router) {
  }

  // onItemSelection( title ) {
  //   if ( title === 'Log out' ) {
  //     //window.localStorage.getItem('AdminOlympusToken')
     
  //   } else if ( title === 'Profile' ) {
  //     // Do something on Profile
  //     var link = this.baseUrl + "profile";
  //     this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  //   }
  // }

  onProfile(){
    var link = this.baseUrl + "profile";
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  onLogOut(){
    localStorage.removeItem("AdminOlympusToken");
    localStorage.removeItem("AdminOlympus_FirstName");
    localStorage.removeItem("AdminOlympus_LastName");
    localStorage.removeItem("AdminOlympus_LodgeId");
    localStorage.removeItem("AdminOlympus_SubLodgeId");
    localStorage.removeItem("AdminOlympus_UserId");
    localStorage.removeItem("AdminOlympus_Email");
    localStorage.removeItem("AdminOlympus_UserImage");
    localStorage.removeItem("AdminOlympus_Role_id");
    localStorage.removeItem("AdminOlympus_GoogleStoragePath");
    localStorage.removeItem("AdminOlympus_Site");
    this.afAuth.signOut();
    this._router.navigate(['/auth/login']);
  }

  UserImage() {
    if (this.image == null || this.image === 'null') {
      return 'assets/images/user.png';
  
    }
    else {
      
      if (this.image === 'undefined') {
        return 'assets/images/user.png';

      }
      else {
        //return this.googleStoragePath + 'Upload/UserPhoto/thumbs/' + this.image;
        return this.image;

      }
    }
  
  }


  ngOnInit() {
    
    if (this.image != null) {
      if (this.image === 'undefined') {
        this.chkimg = false;
      }
      else {
       // this.imgurl = this.googleStoragePath + 'Upload/UserPhoto/thumbs/' + this.image;
        this.chkimg = true;
      }

  }
  else {
    this.chkimg = false;
  }
    if (this.name != null){
      this.firstchar = this.name.slice(0, 1);
    }
    // this.currentTheme = this.themeService.currentTheme;

    // this.menuService.onItemClick().subscribe(( event ) => {
    //   this.onItemSelection(event.item.title);
    // })

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  // }

  // toggleSidebar(): boolean {
  //   this.sidebarService.toggle(true, 'menu-sidebar');
  //   this.layoutService.changeLayoutSize();

  //   return false;
  // }

  // navigateHome() {
  //   this.menuService.navigateHome();
  //   return false;
  // }
}
