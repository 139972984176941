import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
        <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="footerLogo">
                <a href="javascript:void(0)"><img src="assets/images/logo.svg" alt=""></a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="copyRight">Copyright © {{getCurrentYear()}}. Olympus. All Rights Serserved.</div>
            </div>
          </div>
        </div>
      </footer>
  `,
})
export class FooterComponent {

getCurrentYear() {
  return new Date().getFullYear();
}

}
