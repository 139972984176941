import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GrandLodgeService {
  _getHeaders() {
    let key = 'AdminOlympusToken';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;


  GetLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Lodge/GetSingleLodge/"+ obj,headers);
  }


  GetAllGrandLodge(): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/GrandLodge/GetAllGrandLodge",headers);
  }

  GetSingalGrandLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/GrandLodge/GetSingalGrandLodge/"+ obj,headers);
  }

  PutUpdateGrandLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/GrandLodge/PutUpdateGrandLodge", obj,headers);
  }

  PostAddGrandLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/GrandLodge/PostAddGrandLodge", obj,headers);
  }

  DeleteGrandLodge(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };

    return this.http.delete(this.baseUrl+"api/GrandLodge/DeleteGrandLodge", options);
  }

}
