import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetContry() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get('assets/data/country.json',headers);
  }

  GetUserDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetUserDetail/"+obj,headers);
  }

  GetProfileDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetProfileDetail/"+obj,headers);
  }

  GetConcordentDetail(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/User/GetConcordentDetail"+obj,headers);
  }

  PutUserUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/User/PostAdminUserUpdate?offset=" + offset, obj,headers);
  }

  PostUserNew(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/User/PostUserNew?offset=" + offset, obj,headers);
  }


}
